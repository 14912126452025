import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { ScreenText } from '@/lang/ScreenText';
import attendanceStore from '@/store/modules/attendanceStore';
import { getDayLabel, daysMapping } from '@/utilities/cmsUtilities';

@Component({})
export default class MovableTableComponent extends Mixins(DeviceWidthCheckerMixin) {

    public tableHeaders:any = [];
    public elementScroller: any = document.getElementById('scroll-bar')
      ? document.getElementById('scroll-bar')
      : {style: {width: ''}};
    public element: any = document.getElementById('tableResizableScholar')
      ? document.getElementById('tableResizableScholar')
      : {offsetWidth: ''};
    public leftTableEle: any = document.getElementById('submissions-fixed-table');
    public selectedHeader: any = {};
    public scrollTimeout: any = {};
    public drill:any=false;
    public dynamicHeight: string = '';
    public dynamicWidth: string = '';
    public mainContainerWidth = APP_CONST.ZERO;
    public tableWidth = APP_CONST.ZERO;
    public hoverText: string = '';
    public hoverId: number = 0;
    public hoverStyleObj:any = {};
    public isTooltip: boolean = false;
    private objScreenText: ScreenText = new ScreenText();
    public attendanceIndex: number = 0;
    private ascOrder:string = 'asc';
    private descOrder:string = 'desc';
    public dayArray:any=['Su','M','T','W','Th','F','S'];
    public sortingDropdownStyleObj:any = {top:'',left:''};
    public scIndex: number = APP_CONST.MINUS_ONE;
    public attIndex: number = APP_CONST.MINUS_ONE;
    public hoverIndex:number = -1;

    @Prop()
    showSingleClickedDay!:any;
    
    @Prop()
    movableTableHeaders!: any;

    @Prop()
    tabSelectedValue!:any;

    @Prop()
    tabSelected!:any;

    @Prop()
    scholarAttedanceList!:any;

    @Prop()
    currentClickedDayIndex!: any;

    @Prop()
    currentDissmisedIndex!: string;

    @Prop()
    singleScholarCheckinIndex!: string;

    @Prop()
    currentAttendanceDDRef!: string;

    @Prop()
    attedanceList!: any;

    @Prop()
    mobileView!: any;

    @Prop()
    dayNotesIndex!: string;

    @Prop()
    dayNotesValue!: string;

    @Prop()
    updateTime!: string;

    @Prop()
    filteredColumns!: any;

    @Prop()
    eleHeightHeader!: any;

    @Prop()
    currentWeek!: any;

    @Prop()
    currentSortedColumn!: any;

    @Prop()
    isMobileForAPiCall!: boolean;

    @Prop()
    currentSortOrder!: any;

    @Prop()
    transportationSorted!: any;

    @Prop()
    weekSort!: string;

    @Prop()
    staffCheck!: boolean;

    @Prop()
    scholarAttedanceRef!: any;

    @Prop()
    currentSearchingDay!: string;

    @Prop({ default: false })
    enhanced!:boolean;

    @Prop({ default: 0 })
    totalCheckInScholarCount!: number;

    @Prop({ default: 0 })
    totalCheckedOutScholarCount!: number;

    @Prop({ default: 0 })
    totalActivePagingScholar!: number;

    @Watch('tabSelected', {deep: true, immediate:true})
    updateTabValue(val:any) {
      this.tableHeaders = this.tabSelectedValue == 'Day'
        ? (val == 'Check-in'
          ? this.checkInTableHeaders
          : (this.enhanced
            ? APP_CONST.ATTENDANCE_HEADER_DESKTOP.CHECKOUT
            : APP_CONST.LEGACY_ATTENDANCE_HEADER_DESKTOP.CHECKOUT))
        :'';
    }

    /* istanbul ignore next */
    get checkInTableHeaders(){
      setTimeout(() => {
        this.updateTableView();
      }, 50);
      return attendanceStore.checkInHeaders;
    }

    /* istanbul ignore next */
    get checkOutTableHeaders(){
      setTimeout(() => {
        this.updateTableView();
      }, 50);
      return attendanceStore.checkOutTableHeaders;
    }

    /* istanbul ignore next */
    get weekTransporation() {
      setTimeout(() => {
        this.updateTableView();
      }, 50);
      return attendanceStore.weekTransporation;
    }

    /* istanbul ignore next */
    get checkInResizeObj(){
      return attendanceStore.checkInResizeObj;
    }

    /* istanbul ignore next */
    get checkOutResizeObj(){
      return attendanceStore.checkOutResizeObject;
    }

    /* istanbul ignore next */
    get checkInWeekResizeObjFromStorage(){
      return attendanceStore.checkInWeekResizeFromStorage;
    }

    /* istanbul ignore else */
    get transportationFilter(){
      return attendanceStore.isTransportationFilterOpen;
    }
    get gradeFilter(){
      return attendanceStore.isGradeFilterOpen;
    }

    get attendancePayload(){
      return attendanceStore.attendanceAPIPayload;
    }

    sortIcon(header?: any){
      if(this.tabSelectedValue =='Week' && this.tabSelected == 'Check-in' && this.attendancePayload.sortField.toLowerCase()==this.attendancePayload.SearchField.toLowerCase()){
        if((this.currentSearchingDay == header && this.attendancePayload.SearchField == 'Attendance') || this.attendancePayload.SearchField == 'ArrivalPlan'){
          return this.attendancePayload.sortDir == 1
            ? 'sort-a-to-z-white'
            : 'sort-z-to-a-white';
        }
        else{
          return this.attendancePayload.sortDir == 1
            ? 'sort-a-to-z-green'
            : 'sort-z-to-a-green';
        }
      }
      else if(this.attendancePayload.sortField.toLowerCase()==this.attendancePayload.SearchField.toLowerCase()){
        return this.attendancePayload.sortDir == 1
          ? 'sort-a-to-z-white'
          : 'sort-z-to-a-white';
      }
      else {
        return this.attendancePayload.sortDir == 1
          ? 'sort-a-to-z-green'
          : 'sort-z-to-a-green';
      }
    }

    public isNotesDisable(record: any){
      /* istanbul ignore else */
      if((!record.attendance[0].attendance) || (record.attendance[0].attendance == 'R')){
        return true;
      }
      return false;
    }

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    public makeDateString(date: any) {
      date = new Date(date);
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const y = date.getFullYear();
      const dateString = mm + '/' + dd + '/' + y;
      return dateString;
    }
  
    singleScholarCheckIn(event: any, scIndex: number, attIndex: number, value:string){   
      if (this.staffCheck && this.scholarAttedanceList) {
        const attendance = this.scholarAttedanceRef['item_' + this.scholarAttedanceList[0].id + '_' + attIndex].attendanceDate;
        const attendanceDate = this.makeDateString(new Date(attendance));
        /* istanbul ignore else */
        if (this.makeDateString(new Date()) != attendanceDate) {
          return;
        }
      }
      this.$emit('singleScholarCheckIn', event, scIndex, attIndex);
      const element:any = document.getElementsByClassName(value);
      element && element.length && element.forEach((div:any) => {
        div.classList.add('currentColumn');
        if (!this.enhanced) {
          div.style.minWidth = '212px';
          div.style.maxWidth = '212px';
        }
      });     
    }

    openDropdown(event:any, scIndex: number, attIndex: number, attRecord: any){
      const id = 'D' + scIndex + attIndex + '0';
      const elem = <HTMLDivElement>document.getElementById(id);
      const pos = elem && elem.getBoundingClientRect();
      let xx = pos && pos.left;
      const yy = pos && pos.top;
      const xPosPercent = (xx / window.innerWidth) * 100;
      xx = xx - (xPosPercent >= 76
        ? 100
        : APP_CONST.ZERO);

      const x = xx && xx || 0;
      const y = yy && yy || 0;
      const xCord = (x).toString() + 'px';
      const yCord = (y).toString() + 'px';
      this.sortingDropdownStyleObj.left = xCord;
      this.sortingDropdownStyleObj.top = yCord;
      this.$emit('openDropdown', event, scIndex, attIndex, attRecord);
    }

    staffLoginCheck(event:any, dayIndex: any){
      this.$emit('staffLoginCheck', event, dayIndex);
    }

    staffLoginCheckClass(dayIndex: any) {
      if (this.staffCheck && this.scholarAttedanceList) {
        const attedance = this.scholarAttedanceRef['item_' + this.scholarAttedanceList[0].id + '_' + dayIndex].attendanceDate;
        const attendanceDate = this.makeDateString(new Date(attedance));
        if (this.makeDateString(new Date()) != attendanceDate) {
          return true;
        }
      }
      return false;
    } 

    closeCurrentDropdown(event: any, scIndex: number, dayIndex: number, attendanceType: string){
      this.$emit('closeCurrentDropdown', event, scIndex, dayIndex, attendanceType);
    }

    markIndividualAttendance(event: any, scIndex: number, attIndex: number, listIndex: any,remarks:string){
      this.$emit('markIndividualAttendance', event, scIndex, attIndex, listIndex,remarks);
    }

    markDismissedScholar(attIndex: any, scIndex: any, value: string){
      this.$emit('markDismissedScholar', attIndex, scIndex);
      const element:any = document.getElementsByClassName(value);
      element && element.length && element.forEach((div:any) => {
        div.classList.add('currentColumn');
        if (!this.enhanced) {
          div.style.minWidth = '212px';
          div.style.maxWidth = '212px';
        }
      });
    }

    openDissmissedView(scIndex : number, attIndex : number, value: string){
      this.$emit('openDissmissedView', scIndex, attIndex);
      const element:any = document.getElementsByClassName(value);
      element && element.length && element.forEach((div:any) => {
        div.classList.add('currentColumn');
        if (!this.enhanced) {
          div.style.minWidth = '212px';
          div.style.maxWidth = '212px';
        }
      });
    }

    closeDropdown(){
      this.$emit('closeDropdown');
    }

    resetData(record: any){
      this.$emit('resetData', record);
    }

    onClickInputDay(event: any,scIndex: number, attIndex: number){
      this.scIndex = scIndex;
      this.attIndex = attIndex;
      this.setTimerDropdownPosition();
      this.$emit('onClickInputDay', event, scIndex, attIndex);
    }

    setTimerDropdownPosition(){
      if (!this.isMobile) {
        const id = 'startTimeDay' + this.scIndex + this.attIndex;
        const elem = <HTMLDivElement>document.getElementById(id);
        if (elem) {
          const pos = elem.getBoundingClientRect();
          let xx = pos.left;
          let yy = pos.top;
          const xPosPercent = (xx / window.innerWidth) * APP_CONST.HUNDRED;
          xx = xx - (xPosPercent >= APP_CONST.SEVENTY_SIX
            ? APP_CONST.HUNDRED
            : APP_CONST.ZERO);
          yy = yy + APP_CONST.FORTY;
          const x = xx && xx || APP_CONST.ZERO;
          const y = yy && yy || APP_CONST.ZERO;
          const xCord = (x).toString() + 'px';
          const yCord = (y).toString() + 'px';
          const parentNode = elem && elem.parentNode;
          if (parentNode && parentNode.children) {
            const elemArray = Array.from(parentNode.children);
            elemArray.forEach((element: any) => {
              if (element.className == 'dropdown drop-down') {
                element.style.top = yCord;
                element.style.left = xCord;
              }
            });  
          }
        }
      }
    }

    checkOutsideClick(){
      this.$emit('checkOutsideClick');
    }

    markIndividualAttendanceDay(){
      this.$emit('markIndividualAttendanceDay');
    }

    checkInTimes(event: any, scIndex: number){
      this.$emit('checkInTimes', event, scIndex);
    }

    onInputStartTime(event: any){
      this.$emit('onInputStartTime', event);
    }

    updateNotes(scIndex: number){
      this.$emit('updateNotes', scIndex);
    }

    saveDayNotes(scIndex:number, attIndex: number){
      this.$emit('saveDayNotes', scIndex, attIndex, ' ');
    }

    limitedNotesChck(event: any, limit: number, scIndex: number){
      this.$emit('limitedNotesChck', event, limit, scIndex);
    }

    pasteNotesValue(event: any, limit: number, scIndex: number){
      this.$emit('pasteNotesValue', event, limit, scIndex);
    }

    hideHoliday(){
      this.$emit('hideHoliday');
    }

    showHoliday(dayItem: any, dayIndex: number){
      this.$emit('showHoliday', dayItem, dayIndex);
    }
    /* istanbul ignore next */
    mounted() {
      setTimeout(() => {
        const table = document.getElementById('tableResizableScholar');
        if (table) {
          this.createResizableTable(table);
        }
        this.elementScroller = document.getElementById('scroll-bar')
          ? document.getElementById('scroll-bar')
          : { style: { width: '' } };
        this.element = document.getElementById('tableResizableScholar')
          ? document.getElementById('tableResizableScholar')
          : { offsetWidth: '' };
        this.leftTableEle = document.getElementById('submissions-fixed-table');
        this.updateScrollBar();
      }, 100);
      this.$emit('getResizeFromStorage');
      this.element = document.getElementById('tableResizableScholar')
        ? document.getElementById('tableResizableScholar')
        : {offsetWidth: ''};
      const drilldrown = APP_UTILITIES.getCookie('super_user_drilldown');
      this.drill=drilldrown;
      window.addEventListener('mousewheel', this.onMouseWheel, false);
      window.addEventListener('touchmove', this.onMouseWheel, false);
      if (!this.isMobile) {
        window.addEventListener('scroll', this.closeDropdowns, false);
        window.addEventListener('scroll', this.callHoverOut, false);
        const element = document.getElementById('scrollableArea');
        if (element) {
          element.addEventListener('scroll', this.closeDropdowns, false);
        }
      }
      if (document.getElementById('tableResizableScholar')) {
        this.createResizableTable(document.getElementById('tableResizableScholar')); 
      }
      Vue.prototype.$eventHub.$on('changeHeader', this.changeHeader);
    }

    closeDropdowns(){
      this.closeDropdown();
      this.setTimerDropdownPosition();
      this.$emit('closeSortingDropdown');
    }

    /* istanbul ignore next */
    onMouseWheel(e: any) {
      const scrollerView: any = document.getElementById('layerWhenScrolling');
      let d = ((typeof e.wheelDelta != 'undefined')
        ? (-e.wheelDelta)
        : e.detail);
      d = 100 * ((d > 0)
        ? 1
        : -1);
      if (scrollerView && scrollerView.classList) {
        scrollerView.classList.add('stackingElement');
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(function () {
          scrollerView.classList.remove('stackingElement');
        }, 800);
      }
    }

    createResizableTable(table: any) { 
      const cols = table && table.querySelectorAll('.table-cell');
      cols && cols.forEach((col: any) => {
        // Add a resizer element to the column
        const column = document.querySelectorAll(`.${col.id}`);
        const resizer = document.createElement('div');
        resizer.classList.add('resizer');

        // Set the height
        resizer.style.height = `${table.offsetHeight}px`;
        col.appendChild(resizer);
        this.createResizableColumn(col, resizer);

        if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day'){
          this.checInColumnsResizing(column, col);
        }
        else if(this.tabSelected == 'Check-out'){
          this.checOutColumnsResizing(column, col);
        }
        else if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Week'){
          this.checkInWeekResizing(column, col);
        }
      });
    }

    createResizableColumn(col: any, resizer: any) {
      let x = 0;
      let w = 0;

      const mouseDownHandler = function(e: any) {
        x = e.clientX;

        const styles:any = window.getComputedStyle(col);
        w = parseInt(styles.width, 10);

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);

        resizer.classList.add('resizing');
      };

      const mouseMoveHandler = (e: any) =>  { 
        const column = document.querySelectorAll(`.${col.id}`);
        const dx = e.clientX - x;
        let width:any;
        if(col.id == 'time' || col.id == 'note'){
          width = (w + dx) <= 165
            ? 165
            : (w + dx);
        }
        else if(col.id == 'Su'||col.id == 'M'||col.id == 'T'||col.id == 'W'||col.id == 'Th'||col.id == 'F'||col.id == 'S'||col.id == 'check'){
          if(col.className.indexOf('currentColumn') >-1){
            width = (w + dx) <= 211
              ? 211
              : (w + dx);
          }
          else{ 
            width = (w + dx) <= 130
              ? 130
              : (w + dx);

          }
        
        }
        else if(col.id=='arrivalPlan'){
          width = (w + dx) <= 80
            ? 80
            : (w + dx);
        }
        else{ 
          width = (w + dx) <= 120
            ? 120
            : (w + dx);                
        }

        if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day'){
          attendanceStore.mutateCheckinResizeObj({column: col, maxWidth:`${width}px`,minWidth:`${width}px`});
        }
        else if(this.tabSelected == 'Check-out'){
          attendanceStore.mutateCheckOutResizeObj({column: col, maxWidth:`${width}px`,minWidth:`${width}px`});
        }
        else if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Week'){
          attendanceStore.mutateCheckinWeekResizeObj({column: col, maxWidth:`${w + dx}px`,minWidth:`${width}px`});
        }
        col.style.maxWidth = `${w + dx}px`;
        col.style.minWidth = `${width}px`;
        column.forEach((age: any) => {
          age.style.maxWidth = `${w + dx}px`;
          age.style.minWidth = `${width}px`;
        });
      };

      const mouseUpHandler = () => {
        resizer.classList.remove('resizing');
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
        this.updateScrollBar();
      };

      resizer.addEventListener('mousedown', mouseDownHandler);
    }


    scrollAndHeight() {
      this.setHeight();
      setTimeout(() => {
        const table = document.getElementById('tableResizableScholar');
        if (table) {
          this.createResizableTable(table);
        }
        this.elementScroller = document.getElementById('scroll-bar') || { style: { width: '' } };
        this.element = document.getElementById('tableResizableScholar') || { offsetWidth: '' };
        this.leftTableEle = document.getElementById('submissions-fixed-table');
        this.updateScrollBar();
        this.setWidth();
      }, 50);
    }

    updateScrollBar() {
      this.elementScroller.style.width = this.element.offsetWidth.toString()+'px';
      // const columnAvaiable = this.rosterHeader.some((column: any) => {
      //     if (column.display) {
      //         return true;
      //     }
      // })
      // this.elementScroller.style.width = columnAvaiable ? this.elementScroller.style.width : "0";
    }

    setHeight() {
      setTimeout(() => {
        const leftTableEle = document.getElementById('submissions-fixed-table');
        const tableHeight = leftTableEle && leftTableEle.offsetHeight;
        this.dynamicHeight = tableHeight && tableHeight.toString() + 'px' || '';
      }, 0);
    }

    setWidth() {
      setTimeout(() => {
        const leftTableEle = document.getElementById('tableResizableScholar');
        const mainContainer = document.getElementById('smart-table-wrapper');
        const mainContainerWidth = (mainContainer && mainContainer.offsetWidth - APP_CONST.FIVE_HUNDRED_FIFTY) || 0;
        const tableWidth = (leftTableEle && leftTableEle.offsetWidth) || 0;
        this.mainContainerWidth = mainContainerWidth;
        this.tableWidth = tableWidth;
        if (tableWidth < mainContainerWidth){
          if (tableWidth){
            this.dynamicWidth = tableWidth && tableWidth.toString() + 'px' || '';
          }
          else {
            this.dynamicWidth = 0 + 'px' || '';
          }
        }
        else {
          if (tableWidth){
            this.dynamicWidth = '100%';
          }
        }
      }, APP_CONST.FIFTY);
    }

    callHover(index:number, value:string, event: any, id?: number, ){
      const target = event.target;
      if(target.offsetHeight > 32){
        this.isTooltip = true;
        this.hoverText = value;
        this.hoverIndex = index;
        const boundBox = event && event.target && event.target.getBoundingClientRect();
        const coordX = boundBox.left;
        const coordY = boundBox.top;
        this.hoverStyleObj={
          position: 'fixed',
          top:(coordY + 30).toString() + 'px',
          left:(coordX + 30).toString() + 'px'
        };
      }  
    }
        
    callHoverOut(){
      this.hoverText = '';
      this.hoverId = 0;
      this.isTooltip = false;
      this.hoverIndex = -1;
    }

    changeHeader(){ 
      if(this.tabSelectedValue == 'Day' && this.tabSelected == 'Check-in'){
        this.tableHeaders = this.checkInTableHeaders;
      }
    }

    markAttendanceForTheColumn(event: any, attIndex: number, value:string, isBulk:boolean, scIndex:number){
      this.$emit('markAttendanceForTheColumn', event, attIndex,isBulk , scIndex);
      const element:any = document.getElementsByClassName(value);
      const column:any = document.querySelectorAll('.table-cell');
      column && column.length && column.forEach((div:any) => {
        div.classList.remove('currentColumn');
      });
      element && element.length && element.forEach((div:any) => {
        div.classList.add('currentColumn');
        if (!this.enhanced) {
          div.style.minWidth = '212px';
        }
      });
    }

    onClickInput(event: any){
      this.$emit('onClickInput', event);
    }

    changeRemark(event: any,scIndex:number, attIndex:number){
      this.$emit('changeRemark', event, scIndex, attIndex);
    }

    onEnter(event: any,emptyObject: any, scIndex: number, attIndex: number, keyIndex: any,remarks:string ){
      this.$emit('onEnter', event, emptyObject, scIndex, attIndex, keyIndex,remarks);
    }

    sortHeaders(field: string, dayItem?:any){
      this.$emit('sortHeaders', field, dayItem);
    }

    destroyed() {
      window.removeEventListener('scroll', this.closeDropdowns, false);
      const element = document.getElementById('scrollableArea');
      if (element) {
        element.removeEventListener('scroll', this.closeDropdowns, false);   
      }
      this.scIndex = APP_CONST.MINUS_ONE;
      this.attIndex = APP_CONST.MINUS_ONE;
    }

    updateTimeInWeek(event: any,scIndex: number, attIndex: number){
      this.$emit('updateTimeInWeek', event, scIndex, attIndex);
    }

    checInColumnsResizing(column: any, col: any){
      this.checkInResizeObj.forEach((item: { id: string; maxWidth: string; minWidth: string }) => {
        /* istanbuk ignore else */
        if (item.id == col.id) {
          col.style.maxWidth = item.maxWidth;
          col.style.minWidth = item.minWidth;
        }
      });

      column.forEach((colm: any) => {
        this.checkInResizeObj.forEach((item: { id: string; maxWidth: string; minWidth: string }) => {
          const key = `table-cell ${item.id}`;
          if(item.id == 'check'){
            if (`table-cell ${item.id} ${item.id}` == colm.className) {
              colm.style.maxWidth = item.maxWidth;
              colm.style.minWidth = item.minWidth;
            }
          }
          else if(item.id == 'note'){
            if (`table-cell ${item.id} note-input` == colm.className) {
              colm.style.maxWidth = item.maxWidth;
              colm.style.minWidth = item.minWidth;
            }
          }
          else{
            if (`table-cell ${item.id}` == colm.className) {
              colm.style.maxWidth = item.maxWidth;
              colm.style.minWidth = item.minWidth;
            }
          }
        });
      });
    }

    checOutColumnsResizing(column: any, col: any){
      this.checkOutResizeObj.forEach((item: { id: string; maxWidth: string; minWidth: string }) => {
        if (item.id == col.id) {
          col.style.maxWidth = item.maxWidth;
          col.style.minWidth = item.minWidth;
        }
      });

      column.forEach((colm: any) => {
        this.checkOutResizeObj.forEach((item: { id: string; maxWidth: string; minWidth: string }) => {
          const key = `table-cell ${item.id}`;
          if(item.id == 'check'){
            if (`table-cell ${item.id} ${item.id}` == colm.className) {
              colm.style.maxWidth = item.maxWidth;
              colm.style.minWidth = item.minWidth;
            }
          }
          else if(item.id == 'note'){
            if (`table-cell ${item.id} note-input` == colm.className) {
              colm.style.maxWidth = item.maxWidth;
              colm.style.minWidth = item.minWidth;
            }
          }
          else{
            if (`table-cell ${item.id}` == colm.className) {
              colm.style.maxWidth = item.maxWidth;
              colm.style.minWidth = item.minWidth;
            }
          }
        });
      });
    }

    checkInWeekResizing(column: any, col:any){
      this.checkInWeekResizeObjFromStorage.forEach((item: { id: string; maxWidth: string; minWidth: string }) => {
        if (item.id == col.id) {
          col.style.maxWidth = item.maxWidth;
          col.style.minWidth = item.minWidth;
        }
      });

      column.forEach((colm: any) => {
        this.checkInWeekResizeObjFromStorage.forEach((item: { id: string; maxWidth: string; minWidth: string }) => {
          const key = `table-cell ${item.id}`;
          if(item.id != 'arrivalPlan'){
            if (`table-cell check ${item.id}` == colm.className) {
              colm.style.maxWidth = item.maxWidth;
              colm.style.minWidth = item.minWidth;
            }
          }
          else{
            if (`table-cell ${item.id}` == colm.className) {
              colm.style.maxWidth = item.maxWidth;
              colm.style.minWidth = item.minWidth;
            }
          }
        });
      });
    }

    openSortingDropdown(header: any, event: any, dayItem?: any) { 
      let elem: any;
      if(header == 'Attendance'){
        if(this.tabSelectedValue == 'Week'){
          elem = <HTMLDivElement>document.getElementById(dayItem.day);
        }
        else{
          elem = <HTMLDivElement>document.getElementById(header);
        }
      }
      else if(header == 'transportation' || header === 'grade'){
        elem = <HTMLDivElement>document.getElementById(header);
      }
      else {
        elem = <HTMLDivElement>document.getElementById(header.value);
      }
      const pos = elem && elem.getBoundingClientRect();
      // if(pos.left < 580){
      //     return
      // }
      if(pos){
        const x = this.tabSelectedValue == 'Day'
          ?  pos.left + APP_CONST.FIVE
          : pos.left + APP_CONST.FOURTEEN;
        const y = this.tabSelectedValue == 'Week' && dayItem != null
          ? pos.top + APP_CONST.FOURTY_FIVE
          : pos.top + APP_CONST.TWENTY_FIVE;
        if(header == 'Attendance'){
          this.$emit('openSortingDropdown', header, { x, y }, dayItem);
        }
        else {
          this.$emit('openSortingDropdown', header, { x, y });
        }
      }
        
    }

    updateTableView(){  
      this.scrollAndHeight();
    }

    resizableTable(){
      if (document.getElementById('tableResizableScholar')) {
        this.createResizableTable(document.getElementById('tableResizableScholar')); 
      }
    }

    updated(){
      const movableTableHeader= <HTMLElement>document.getElementById('movable-table-header');
      const movableTableData= <HTMLElement>document.getElementById('scrollableArea');

      setTimeout(() => {
        movableTableData.scrollLeft = movableTableHeader.scrollLeft;
      }, 30);

    }

    formatDay(dayKey: keyof typeof daysMapping): string {
      return getDayLabel(dayKey);
    }
}